<template>
    <div class="bg-white m-10 shadow rounded-lg p-6">
        <h3 class="text-xl mb-4 font-semibold">Train Custom Model for {{service.displayName}}</h3>
        <!-- {{service}} -->
        <br/>
        <div>
            <button class="text-blue-500" 
                v-on:click="toggleShowInstructions">Please click here for training data requirements</button>
            <div v-if="show_instructions" style="text-align:left;">
                <div style="white-space: pre-line;">{{instructions}}</div>
            </div>
            <br>	
            <div >
                <button class="rounded bg-gray-300 hover:bg-gray-400 text-gray-800 py-1 px-2 rounded-l" 
                    @click="click1">Upload Training Data (zip)</button>
                <input type="file" ref="input1" style="display: none" @change="previewFile" accept=".zip">
            </div>
            <section v-if="fileData!=null" >
                <p>
                    Progress: {{uploadValue.toFixed()+"%"}}
                    <progress id="progress" :value="uploadValue" max="100"/>
                </p>
            </section>
        </div>
        <div>
            <br>
            <select class="form-select" v-model="dataset_name" @change="dataset_url = null">
                <option :value='null' selected disabled>Choose a dataset that you uploaded </option>
                <option v-for="name in datasets" :value="name" :key="name">{{ name }}</option>
            </select>
            <br>
            <br>
            <p style="white-space: pre-line;">Or please point to the dataset (zip) URL address</p>
            <textarea class="border" v-model="dataset_url" @input="dataset_name = null" id="url"/>
            <br>
            <br>
            <p style="white-space: pre-line;">Please enter a name for your model</p>
            <textarea class="border" v-model="modelName" id="modelname"/>
            <br>
               <div v-if="service.name == 'question-answering'">
                   <br/>
                   <input type="checkbox" id="checkbox" v-model="genEmbedOnly"> Use an existing model to update passage/document embeddings
                   <br/>
                   <div v-if="genEmbedOnly">
                       <br/>
                        <p style="white-space: pre-line;">Please enter pre-trained model name</p>
                        <textarea class="border" v-model="pretrainedModel" id="pretrained_model" ></textarea>
                   </div>
               </div>
            <br>
            <button class="rounded text-gray-100 px-3 py-1 bg-blue-500 hover:shadow-inner hover:bg-blue-700 transition-all duration-300"
                    :disabled="pausedFor2Minutes || waitingForResponse" @click="launchTraining()">
                <p v-if="!waitingForResponse">Launch Training</p>
                <Spinner v-else/>
            </button> 
            <br/>
            <br/>
            <RequestExample url='https://enterprise.slicex.ai/train/language' :payload="payload"/>
        </div>
    </div>
</template>

<script>
import 'firebase/storage';
import RequestExample from "@/components/cli/RequestExample.vue"
import Spinner from "@/components/ui/Spinner.vue"
import axios from "axios";
import firebase from 'firebase';
import { db } from '../main';
import { inject } from 'vue'
import { training_instructions } from "../assets/training-instructions"
import { training_instructions_qa } from "../assets/training-instructions-question-answering"
import { training_instructions_ner } from "../assets/training-instructions-ner"

export default {
    components: {
        RequestExample,
        Spinner,
    },
    props: {
        service: Object,
        uuid: String,
    },
    setup() {
        const privateKey = inject("private_key")
        const publicKey = inject("public_key")
        return {
            privateKey,
            publicKey,
        }
    },
    data() {
        return {
            datasets: [],
            file: '',
            fileData: null,
            fileName:'',
            modelName:'',
            dataset_url: null,
            dataset_name: null,
            waitingForResponse: false,
            uploadValue: 0,
            pretrainedModel: '',
            genEmbedOnly: false,
            show_instructions: false,
            pausedFor2Minutes: false,
        }
    },
    computed: {
        payload: function() {
            const header = {
                x_public_key: this.publicKey,
            }
            const data = {
                user_id: this.uuid,
                private_key: this.privateKey,
                public_key: this.publicKey,
                model_name: this.modelName,
                task_name: this.service.name,
            }

            if (this.dataset_url) data.dataset_url = this.dataset_url
            if (this.dataset_name) data.dataset_name = this.datasetPath(this.dataset_name)

            if (this.service.name === "question-answering") {
                if (this.genEmbedOnly) {
                    data.gen_embed_only = this.genEmbedOnly
                    data.pretrained_model = this.pretrainedModel
                }
            }

            return data
        },
        instructions: function() {
            if (this.service.name === "question-answering") return training_instructions_qa
            else if (this.service.name === "named-entity-recognition") return training_instructions_ner
            else return training_instructions
        },
        preconditionIssues: function() {
            if (this.modelName === "") return "Enter a non-empty model name"
            if (this.dataset_url === null && this.dataset_name === null) return "Select dataset or enter dataset url"
            if (this.genEmbedOnly && this.pretrainedModel === "") return "Enter the pre-trained model"
            return false
        }
    },
    mounted() {
        this.mounted = true
        this.loadDatasets()
    },
    methods: {
        datasetPath(dataset_name) {
            return this.uuid + '/Datasets/' + this.service.type + '/' + this.service.name + '/' + dataset_name
        },
        loadDatasets() {
            // Load user models
            db.collection('UserData').doc(this.uuid).collection('Datasets').doc(this.service.modelPath).get()
            .then(snapshot => {
                if (snapshot.data()) this.datasets = [].concat(snapshot.data().name)
                else this.datasets = []
            })
        },
        toggleShowInstructions() {
            this.show_instructions = !this.show_instructions
        },
        launchTraining() {
            if (this.preconditionIssues) {
                alert(this.preconditionIssues)
            } else {
                this.waitingForResponse = true
                axios.post('https://enterprise.slicex.ai/train/language', this.payload).then((res) => {
                    alert('Training being scheduled, creating data and resources.')
                    this.waitingForResponse = false
                    this.pause()
                }).catch((err) => {
                    alert(err.response.data)
                    this.waitingForResponse = false
                })
            }
        },
        pause(){
            this.pausedFor2Minutes = true,
            setTimeout(() => this.pausedFor2Minutes = false, 60000);
        },
        click1() {
            this.$refs.input1.click()   
        },
        previewFile(event) {
            this.uploadValue=0;
            this.file=null;
            this.fileData = event.target.files[0];
            this.fileName = event.target.files[0].name;
            this.onUpload()
        },
        onUpload(){
            this.file=null;
            const storageRef=firebase.storage().ref(this.datasetPath(this.fileName)).put(this.fileData);
            storageRef.on(`state_changed`,snapshot=>{
                this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
            }, error=>{console.log(error.message)}, ()=>{
                    this.uploadValue=100;
                    this.updateDatabase()
                    storageRef.snapshot.ref.getDownloadURL().then((url)=>{
                        this.file =url;
                        console.log(this.file)
                    });
                }
            );
        },
        updateDatabase(){
            const data = db.collection('UserData').doc(this.uuid).collection('Datasets').doc(this.service.modelPath);
            data.set({name: firebase.firestore.FieldValue.arrayUnion(...[this.fileName])}, {merge: true});
            data.get().then(snapshot => this.datasets = this.datasets.concat(snapshot.data().name).filter((value,pos)=>{return this.datasets.concat(snapshot.data().name).indexOf(value) == pos;}));
        }
    },
    watch: {
        "service": {
            handler: function() {
                this.loadDatasets()
                this.show_instructions = false
                this.modelName = ""
                this.dataset_url = null
                this.dataset_name = null
            },
            deep: true,
            immediate: false,
        },
    },
}
</script>

<style scoped>

textarea {
	width: 200px;
	height: 30px;
}
#url {
     width:400px;
}
</style>