<template>
	<div class="bg-white m-10 shadow rounded-lg p-6">
		<h2 class="text-xl mb-4 font-semibold">Loading {{".".repeat(count)}}</h2>
	</div>
</template>

<script>
export default {
	data() {
		return {
			count: 0,
		}
	},
	methods: {
		updateDots() {
			this.count = (this.count + 1) % 4
		},
	},
	mounted() {
		setInterval(this.updateDots, 200)
	},
}
</script>

<style scoped>
</style>
