<template>
    <div v-if="response != null" class="text-left">
        <div v-if="!qa && !ner">
            <ul class="pb-2" v-for="(n,index) in numToDisplay" :key="index">
                <li class="font-medium">{{response.labels[index]}} </li>
                <li v-if="response.scores" class="text-sm"> score: {{response.scores[index].toFixed(2)}} </li>
            </ul> 
        </div>
        <div v-if="ner">
            <ul class="pb-2" v-for="(n,index) in numToDisplay" :key="index">
                <li class="font-medium">{{response.tokens[index]}},  {{response.labels[index]}} </li>
                <li class="text-sm"> token position: {{response.token_start_positions[index]}} </li>
                <li class="text-sm"> score: {{response.scores[index].toFixed(2)}} </li>
            </ul> 
        </div>
        <div v-if="qa">
            <ul class="pb-4" v-for="(n,index) in numToDisplay" :key="index">
                <li class="font-medium"> {{response.labels[index].split("---")[0]}} </li>
                <li class="text-sm text-blue-800"> {{response.labels[index].split("---")[1]}} </li>
                <li class="text-xs"> {{response.labels[index].split("---")[2]}} </li>
                <li class="text-sm"> score: {{response.scores[index].toFixed(2)}} </li>
            </ul> 
        </div>
    </div>
</template>

<script>
export default {
    name: "InferenceResponse",
    props: {
        response: Object,
        service_type: String
    },
    data() {
        return {
            numToDisplay: 0,
            qa: false
        }
    },
    computed: {
        numToDisplay: function() {
            if (["multilabel-text-classification", "multiclass-text-classification"].includes(this.service_type)) {
                return Math.min(3, this.response.labels.length)
            } else {
                return this.response.labels.length
            }
        },
        qa: function() {
            return this.service_type === "question-answering"
        },
        ner: function() {
            return this.service_type === "sequence-labeling"
        },
    },
    mounted () {
    }
}
</script>