<template>
  <Line :chart-data="chartData" :chart-options="chartOptions"/>
</template>

<script>
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: { Line },
  props: [
      'data',
      'label',
      'epochs',
      'title'
  ],
  data() {
    return {
      chartData: {
        labels: this.epochs,
        datasets: [
          {
              label: this.title,
              backgroundColor: 'rgb(30, 64, 175)',
              borderColor: 'rgb(30, 64, 175)',
              tension: 0.1,
              data: [null].concat(this.data)
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        spanGaps: false,
        scaleShowValues: true,
        scales: {
            yAxes: [{
                display: true,
                ticks: {
                    beginAtZero: true,
                    max: 1
                }
            }],
            xAxes: [{
                ticks: {
                    autoSkip: false
                }
            }]
        }
      }
    }
  }
}
</script>