<template>
    <div v-if="training" class="container">
        <!-- To run this command in CLI (Python), use the following:	 -->
        <pre class="command"><code class="code" v-for="line in request_training" :key="line">{{line}}</code></pre>
    </div>
    <div v-if="!training" class="container">
        <!-- To run this command in CLI (Python), use the following:	 -->
        <pre class="command"><code class="code" v-for="line in request_inference" :key="line">{{line}}</code></pre>
    </div>
</template>

<script>
export default {
    props: {
        url: String,
        payload: Object,
        headers: Object,
        training: Boolean
    },
    computed: {
        request_training: function() {
            // import package
            const result = []
            result.push("import requests")
            result.push("")

            // headers
            result.push("headers = {")
            for (const key in this.headers) {
                result.push('   "' + key + '":' + this.stringifyArgument(this.headers[key]) + ",")
            }
            result.push("}")
            result.push("")

            // payload
            result.push("payload = {")
            for (const config in this.payload) {
                if (config == "dataset_url") {
                        result.push('   "' + config + '": ' + this.stringifyArgument(this.payload[config]))
                    } else{
                        result.push('   "' + config + '": {')
                        for (const key in this.payload[config]) {
                            if (key == "preprocessing") {
                                result.push('       "' + key + '": {')
                                result.push('           "' + 'lowercase' + '": ' + 'True' + ",")
                                result.push("       }")
                            } else {
                                result.push('       "' + key + '": ' + this.stringifyArgument(this.payload[config][key]) + ",")
                            }
                        }
                        result.push("   },")   
                }
                
            }
            result.push("}")
            result.push("")

            // command
            result.push("response = requests.post(")
            result.push('   "' + this.url + '",')
            result.push('   headers=headers,')
            result.push('   json=payload,')
            result.push(')')
            for(var i=1; i < result.length; i++) {
                result[i] = "\n" + result[i]
            }
            return result
        },
        request_inference: function() {
            const result = []
            result.push("import requests")
            result.push("")

            // headers
            result.push("headers = {")
            for (const key in this.headers) {
                result.push('   "' + key + '":' + this.stringifyArgument(this.headers[key]) + ",")
            }
            result.push("}")
            result.push("")

            // payload
            result.push("payload = {")
            for (const key in this.payload) {
                result.push('   "' + key + '":' + this.stringifyArgument(this.payload[key]) + ",")
            }
            result.push("}")
            result.push("")

            // command
            result.push("response = requests.post(")
            result.push('   "' + this.url + '",')
            result.push('   headers=headers,')
            result.push('   json=payload,')
            result.push(')')
            for(var i=1; i < result.length; i++) {
                result[i] = "\n" + result[i]
            }
            return result
        }
    },
    methods: {
        stringifyArgument(arg) {
            if (typeof arg == "boolean") {
                if (arg) return "True"
                else return "False"
            }
            if (arg.constructor == Object) {
                for (const key in arg) {
                    return '{"' + key + '":"' + arg[key] + '"}'
                }
            }
            return '"' + arg + '"'
        },
    }
}
</script>

<style scoped>
.container {
    border-style: dotted;
    border-width: 1px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 610px;
    background-color: #F9FAFB;
}
.command {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    /* color: #1e3a8a; */
    color: rgb(55 65 81);
    font-size: 14px;

}
.code{
    font-family: 'Source Code Pro', monospace;
}
</style>
