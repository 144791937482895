<template>
    <div class="bg-white">
        <div class="flex py-5 px-12 justify-start items-center">
            <Cog8ToothIcon class="h-10 w-10 px-1"/>
            <h2 class="text-3xl font-bold">Train Model</h2>
        </div>
        <div class="w-1/2 pb-5 mx-auto">
            <p class="pb-8 text-sm leading-relaxed text-left text-gray-500">Select a task, dataset and model parameters:</p>
            <form v-on:submit="submitTrainJob" v-on:submit.prevent="onSubmit">
                <div class="mb-4">
                    <label for="model_name" class="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Model name</label>
                    <input v-model="model_config.name" type="text" id="model_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter model name (e.g. my_classification_model_1)" required>
                </div> 
                <div class="mb-4">
                    <h3 class="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Task</h3>
                    <ul class="items-center text-left text-sm w-full font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                        <li v-for="task in training_services" :key='task' class="w-full">
                            <div class="flex items-center pl-2">
                                <input v-model="model_config.type" :value="task.type" id="task" type="radio" name="list-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                                <label for="task" class="py-2 ml-2 my-auto w-full dark:text-gray-300">{{task.displayName}}</label>
                            </div>
                        </li>
                    </ul>
                </div> 
                <div class="mb-4">
                    <label class="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="file_input">Dataset</label>
                    <div style="cursor: pointer;" v-on:click="showRequirements" class="flex p-2 mb-3 text-sm text-gray-700 bg-gray-100 hover:bg-blue-100 hover:text-blue-700 rounded-lg dark:bg-gray-700 dark:text-gray-300" role="alert">
                        <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                        <span class="sr-only">Info</span>
                        <div>
                            <span class="font-medium">Info!</span> Click to see datasets requirements.
                        </div>
                    </div>
                    <p class="my-1 text-left text-sm text-gray-500 dark:text-gray-300" id="file_input_help">Upload or enter a URL (File must be ZIP)</p>
                    <div class="flex items-center justify-center w-full">
                        <label for="dropzone-file" @drop="onFileChange($event)" @drop.stop.prevent @dragenter.prevent @dragover="checkDrop" @dragleave="dragging=false"
                            :class="[dragging ? 'border-blue-600' : 'border-gray-300']"
                            class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                            <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                <svg class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                <p class="mb-2 text-sm text-gray-500 "><span class="font-semibold">Click to upload</span> or drag and drop</p>
                                <p v-if="!zip" class="text-xs text-red-500">Please upload a ZIP file</p>
                            </div>
                            <input v-on:change="onFileChange" accept=".zip" id="dropzone-file" type="file" class="hidden" />
                        </label>
                    </div>
                    <section v-if="uploading" class="pt-2 pb-4">
                        <p class="font-medium text-gray-500 text-sm">
                            Uploading {{uploadedFileName}} - Progress: {{uploadValue.toFixed()+"%"}}
                        </p>
                        <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                            <div class="bg-blue-600 h-2.5 rounded-full" :style="{width: + uploadValue.toFixed() + '%'}"></div>
                        </div>
                    </section> 
                    <input type="text" v-model="dataset_url" id="dataset" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter dataset URL if you haven't uploaded">
                </div> 
                <h3 v-on:click="showModelParams=!showModelParams" style="cursor: pointer;" class="block text-left mb-4 text-sm font-medium text-gray-900 dark:text-gray-300">Configure model parameters →</h3>
                <div v-if="showModelParams">
                    <div class="grid gap-6 mb-4 md:grid-cols-4">
                        <div>
                            <label for="learning_rate" class="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Learning Rate</label>
                            <input v-model="training_config.learning_rate" type="text" id="learning_rate" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="1e-3" required>
                        </div>
                        <div>
                            <label for="batch_size" class="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Batch Size</label>
                            <input v-model="training_config.batch_size" type="text" id="batch_size" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="32" required>
                        </div>
                        <div>
                            <label for="epochs" class="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Number of epochs</label>
                            <input v-model="training_config.num_epochs" type="text" id="epochs" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="10" required>
                        </div>
                    </div>  
                    <div class="grid gap-6 mb-4 md:grid-cols-2">
                        <div> 
                            <h3 class="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Model size</h3>
                            <div class="flex">
                                <div class="flex items-center mr-4">
                                    <input v-model="model_config.size" value="mini" id="model_size" type="radio" name="inline-radio-group" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                    <label for="model_size" class="ml-2 my-auto text-sm font-medium text-gray-900 dark:text-gray-300">Small</label>
                                </div>
                                <div class="flex items-center mr-4">
                                    <input v-model="model_config.size" value="base" id="model_size" type="radio" name="inline-radio-group" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                    <label for="model_size" class="ml-2 my-auto text-sm font-medium text-gray-900 dark:text-gray-300">Base</label>
                                </div>
                            </div>
                        </div>
                        <div> 
                            <h3 class="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Initialize with pre-trained model</h3>
                            <div class="flex">
                                <div class="flex items-center mr-4">
                                    <input value="true" v-model="training_config.use_pretrained" id="pretrained" type="radio" name="inline-radio-group2" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                    <label for="pretrained" class="ml-2 my-auto text-sm font-medium text-gray-900 dark:text-gray-300">Yes</label>
                                </div>
                                <div class="flex items-center mr-4">
                                    <input value="false" v-model="training_config.use_pretrained" id="pretrained" type="radio" name="inline-radio-group2" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                    <label for="pretrained" class="ml-2 my-auto text-sm font-medium text-gray-900 dark:text-gray-300">No</label>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label for="pretrained_model_id" class="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Pretrained Model ID</label>
                            <input v-model="training_config.pretrained_model_id" type="text" id="pretrained_model_id" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Initialize training from last checkpoint">
                        </div>
                    </div>
                </div>
                <button type="submit" class="text-white mt-10 bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Submit</button>
            </form>
        </div>
        <div class="relative w-2/4 mx-auto py-4">
            <div class="absolute inset-0 flex items-center">
                <div class="w-full border-b border-gray-300"></div>
            </div>
            <div class="relative flex justify-center">
                <span class="bg-white px-4 text-sm text-gray-500">Command Line</span>
            </div>
        </div>
        <RequestExample :url="apiUrl" :headers="headers" :payload="payload" :training="true"/>
        <br/>
    </div>
</template>

<script>
import 'firebase/storage';
import RequestExample from "@/components/cli/RequestExample.vue"
import Spinner from "@/components/ui/Spinner.vue"
import axios from "axios";
import firebase from 'firebase';
import { db } from '../main';
import { train } from '../meta';
import { inject } from 'vue'
import { training_instructions } from "../assets/training-instructions"
import { training_instructions_qa } from "../assets/training-instructions-question-answering"
import { training_instructions_ner } from "../assets/training-instructions-ner"
import { Cog8ToothIcon } from '@heroicons/vue/24/outline'

export default {
    components: {
        RequestExample,
        Spinner,
        Cog8ToothIcon,
    },
    props: {
        pre_trained_services: [],
        training_services: [],
    },
    setup() {
        const privateKey = inject("private_key")
        const publicKey = inject("public_key")
        const user_id = inject("firebase_uid")
        return {
            privateKey,
            publicKey,
            user_id
        }
    },
    data() {
        return {
            showModelParams: false,
            dragging: false,
            zip: true,
            uploading: false,
            uploadValue: 0,
            uploadedFileName: '',
            training_config: {
                batch_size: 32,
                learning_rate: 1e-3,
                num_epochs: 10,
                language: "english",
                data_augmentation: false,
                use_pretrained: true
            },
            model_config: {
                name: "",
                type: "binary-text-classification",
                family: "Papaya",
                size: "mini",
                preprocessing: {
                    lowercase: true
                }
            },
            dataset_url: '',
        }
    },
    mounted() {
        console.log(this.training_services)
    },
    computed: {
        payload: function() {

            if (["binary-text-classification", "multiclass-text-classification", "multilabel-text-classification"].includes(this.model_config.type)) {
                this.model_config.family = "Papaya"
            } 
            if (["question-answering", "semantic-search"].includes(this.model_config.type)){
                this.model_config.family = "Dragonfruit"
            }
            if (this.model_config.type == "sequence-labeling"){
                this.model_config.family = "Grapefruit"
            }

            const data = {
                training_config: JSON.parse(JSON.stringify(this.training_config)),
                model_config: JSON.parse(JSON.stringify(this.model_config))
            }

            data.dataset_url = this.dataset_url
            
            return data
        },
        headers: function() {
            const headers = {
                'Content-Type': 'application/json',
                'x-public-key': this.publicKey
            }
            return headers
        },
        apiUrl: function() {
            const url =  "https://enterprise.slicex.ai/api/language/trainer/training-jobs"
            return url
        }
    },
    methods: {
        showRequirements(){
            if (["binary-text-classification", "multiclass-text-classification", "multilabel-text-classification"].includes(this.model_config.type)) {
                var path = 'text-classification'
            } else {
                var path = this.model_config.type
            } 
            const urlDataset = "https://enterprise-docs.slicex.ai/api/slicex-ai-trainer/dataset/#" + path
            window.open(urlDataset);
        },
        checkDrop(e) {
            this.dragging= true
            e.preventDefault()
        },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            console.log(files[0])
            if (files[0].type == "application/zip") {
                this.dragging= false
                this.uploadValue = 0
                this.zip = true
                this.uploadDataset(files[0])
            } else {
                this.zip = false
                this.dragging = false
            }
            
        },
        uploadDataset(file){
            const { 
                v1: uuidv1,
            } = require('uuid');
            const file_id = uuidv1()
            const file_name = file_id + '.zip'
            this.uploading = true
            this.uploadedFileName = file.name
            var pathData = this.user_id + '/Data/' + file_name
            this.dataset_url = 'uploaded/' + file_id + "/" + this.user_id

            const storageRef=firebase.storage().ref(pathData).put(file);
            storageRef.on(`state_changed`,snapshot=>{
                this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
            }, error=>{console.log(error.message)}, ()=>{
                    this.uploadValue=100;
                    // this.updateDatabase()
                    storageRef.snapshot.ref.getDownloadURL().then((url)=>{
                        let token = url.split("=").pop();
                        this.dataset_url += "/" + token;
                    });
                }
            );
        },
        submitTrainJob() {
            console.log("in submitTrainJob")
            train(this.headers, this.payload).then((response) => {
                console.log(response)
                alert('Training being scheduled, creating data and resources. Please wait a few minutes before refreshing the page.')
                this.$router.replace('/models')
            }).catch((error) => {
                alert(error)
            })
        }
    }
}
</script>

<style scoped>

#text-form {
	width: 200px;
	height: 30px;
}
</style>