export const training_instructions_qa = `
In a ZIP folder, please include train.json, dev.json, config.json & label_id.json files using the formats below. 

For computing a new passage (or document) index using an existing model, see instructions at the bottom.


1) train.json & dev.json format:

{
  “0”:
    {
      "question": "<question text>"
      "context": "<passage or document text>",
    },
  “1”:
    {
      "question": "<question text>"
      "context": "<passage or document text>",
    },
  …
}

where, the key is an index for the training example and value contains a question-context pair. The “question” refers to the query text & “context” refers to the passage (or document) text that contains the answer to the question. 
Here is an example train.json file.

{ 
  “0”: 
    { 
      “question”: “When was the Compass-M1 satellite launched?”,
      “context”: “The first satellite of the second-generation system, Compass-M1 was launched in 2007. It was followed by further nine satellites during 2009-2011, achieving functional regional coverage. A total of 16 satellites were launched during this phase.”
    },
  “1”:
    {
      “question”: “How many satellites were launched after Compass-M1?”,
      “context”: “The first satellite of the second-generation system, Compass-M1 was launched in 2007. It was followed by further nine satellites during 2009-2011, achieving functional regional coverage. A total of 16 satellites were launched during this phase.”
     }
}


2) config.json format with required fields. e.g.,

{
"desc": “dataset-description”,
"DATASET_NAME": “my-dataset”,
"DATA_DIR": “<path-to-parent-directory-for-data>”,
"TRAIN_FILE": "train.json",
“DEV_FILE": "dev.json",
}


3) label_id.json 

label_id.json contains all the passages (or documents). This file contains the universal collection of passages (or documents) that is used to retrieve the answer to any input question. The format for label_id.json is as follows:

{
  “0”: “<target-passage-text>”,
  “1”: “<target-passage-text>”,
   …
}

where, the key is a passage (or document) index and value is the passage text. 
Here is an example label_id.json file.

{ 
  “0”: “The first satellite of the second-generation system, Compass-M1 was launched in 2007. It was followed by further nine satellites during 2009-2011, achieving functional regional coverage. A total of 16 satellites were launched during this phase.”,
  “1”: “Three-phase AC railway electrification was used in Italy, Switzerland and the United States in the early twentieth century. Italy was the major user, for lines in the mountainous regions of northern Italy from 1901 until 1976. The first lines were the Burgdorf-Thun line in Switzerland (1899), and the lines of the Ferrovia Alta Valtellina from Colico to Chiavenna and Tirano in Italy, which were electrified in 1901 and 1902. Other lines where the three-phase system were used were the Simplon Tunnel in Switzerland from 1906 to 1930, and the Cascade Tunnel of the Great Northern Railway in the United States from 1909 to 1927.”
}


4) Recomputing passage/document index

To run the model over a new passage/document collection, specify the updated collection in the label_id.json file. Then:
- run the CLI command with --pretrained_model (points to an existing model) --gen_embed_only (a boolean argument)
- or enter a name for your model, click on "Use an existing model to update passage/document embeddings" and enter a model you previously trained.


Please contact support@slicex.ai if you have any questions.
`