import axios from "axios";

const URL_BASE = "https://enterprise.slicex.ai"

export async function getServices(public_key) {
    const headers = {
        'Content-Type': 'application/json',
        'x-public-key': public_key
    }

    const config = {
        method: 'get',
        url: URL_BASE + "/api/language/meta/services",
        headers: headers
    }

    try {
        var response = await axios(config)
        return response.data
    } catch(err) {
        console.log(err)
    }
}

export const MODEL_TYPE_MAPPING = {
    "binary-text-classification": "Binary Text Classification",
    "multiclass-text-classification": "MultiClass Text Classification",
    "multilabel-text-classification": "MultiLabel Text Classification",
    "sequence-labeling": "Sequence Labeling",
    "question-answering": "Question Answering",
}

export async function getUserModels(public_key) {
    const headers = {
        'Content-Type': 'application/json',
        'x-public-key': public_key
    }

    const config = {
        method: 'get',
        url: URL_BASE + "/api/language/meta/models",
        headers: headers
    }

    try {
        var response = await axios(config)
        return response.data
    } catch(err) {
        console.log(err)
    }
}

export async function getModelInformation(public_key, model_id) {
    const headers = {
        'Content-Type': 'application/json',
        'x-public-key': public_key
    }

    const config = {
        method: 'get',
        url: URL_BASE + "/api/language/meta/models/" + model_id,
        headers: headers
    }

    try {
        var response = await axios(config)
        return response.data
    } catch(err) {
        console.log(err)
    }
}

export async function getModelTrainingStats(public_key, model_id) {
    const headers = {
        'Content-Type': 'application/json',
        'x-public-key': public_key
    }

    const config = {
        method: 'get',
        url: URL_BASE + "/api/language/trainer/training-jobs/" + model_id,
        headers: headers
    }

    try {
        var response = await axios(config)
        return response.data
    } catch(err) {
        console.log(err)
    }
}

export async function deleteModel(public_key, model_id) {
    const headers = {
        'Content-Type': 'application/json',
        'x-public-key': public_key
    }

    const config = {
        method: 'delete',
        url: URL_BASE + "/api/language/meta/models/" + model_id,
        headers: headers
    }

    try {
        await axios(config)
    } catch(err) {
        console.log(err)
    }
}

export async function infer(public_key, payload, url) {
    const headers = {
        'Content-Type': 'application/json',
        'x-public-key': public_key
    }

    /* const payload = {
        'query': query,
    } */

    const config = {
        method: 'post',
        url: url,
        headers: headers,
        data: payload
    }

    try {
        var response = await axios(config)
        return response.data
    } catch(err) {
        console.log(err)
    }
}

export async function train(headers, payload) {
    const config = {
        method: 'post',
        url: URL_BASE + "/api/language/trainer/training-jobs",
        headers: headers,
        data: payload
    }

    try {
        var response = await axios(config)
        return response.data
    } catch(err) {
        throw err.response.data.detail
    }
}

export async function getUsage(public_key, date=null) {
    const headers = {
        'Content-Type': 'application/json',
        'x-public-key': public_key
    }

    const config = {
        method: 'get',
        url: URL_BASE + "/api/language/meta/usage",
        headers: headers
    }

    try {
        var response = await axios(config)
        return response.data
    } catch(err) {
        throw err.response.data.detail
    }
}