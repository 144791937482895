<template>
    <select class="form-select" v-model="selected" @change="$emit('update:selected', $event.target.value)">
        <option v-if="placeholder" :value="null" selected disabled>{{ placeholder }}</option>
        <option v-for="choice in choices" :value="choice" :key="choice">{{ choice }}</option>
    </select>
</template>

<script>
export default {
    props: {
        choices: Array,
        selected: String,
        placeholder: {
            type: String,
            default(rawProps) {
                return null
            }
        },
    },
    emits: ["update:selected"],
}
</script>
