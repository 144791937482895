<template>
    <textarea class="border" :placeholder="placeholder" v-model="text" @input="$emit('update:text', $event.target.value)"></textarea>
</template>

<script>
export default {
    props: {
        text: String,
        placeholder: String
    },
    emits: [
        'update:text'
    ],
}
</script>

<style scoped>
textarea {
    background-color: #F9FAFB;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    width: 500px;
    height: 150px;
}
</style>
