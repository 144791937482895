<template>
    <div v-if="response != null">
        <p style="white-space: pre-line;">
            label: {{ response.label}}, score: {{ response.score.toFixed(2)}}
        </p>
    </div>
</template>

<script>
export default {
    name: "ClassificationResponse",
    props: {
        response: Object,
    },
}
</script>