<template>
    <div class="bg-white pb-28">
        <h1 class="py-5 px-12 text-left text-3xl font-bold">Custom Models</h1>
        <div class="flex flex-col pb-5 md:flex-row">
            <router-link
                to="trainer"
                class="hover:no-underline hover:text-gray-700 text-blue-900 mx-auto py-12 px-16 rounded-xl border-2 border-gray-400 transition-shadow duration-500 ease-out cursor-pointer hover:shadow-lg">
                <div class="flex justify-start items-center">
                    <Cog8ToothIcon class="h-8 w-8 px-1"/>
                    <h2 class="text-xl font-bold">Train Custom Model</h2>
                </div>
            </router-link>
        </div>
        <div class="relative w-2/4 mx-auto py-4">
            <div class="absolute inset-0 flex items-center">
                <div class="w-full border-b border-gray-300"></div>
            </div>
            <div class="relative flex justify-center">
                <span class="bg-white px-4 text-sm text-gray-500">Existing models</span>
            </div>
        </div>
        <div class="flex">
            <div class="flex items-start pl-12 pr-4">
                <button v-on:click="updateUserModels" type="button" 
                    class="mt-0 mb-6 text-blue-900 hover:text-blue-600 font-medium rounded-lg text-sm w-20 py-2.5 justify-center inline-flex">
                    Refresh
                    <ArrowPathIcon class="ml-2 -mr-1 w-5 h-5" fill="currentColor"/>
                </button>
            </div>
            <input v-model="searchTerm" class="w-42 p-2 mb-4 text-sm border border-gray-300 rounded-lg placeholder-gray-500" placeholder="Search by Model Name"/>
        </div>
        <div v-if="loading" class="text-center">       
            <div class="text-center">
                <svg aria-hidden="true" role="status" class="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
                </svg>
            </div>
        </div>
        <div v-if="toggleModal" class="w-1/2 mx-auto pb-4">
            <WarningModal @childToParent="onWarningModalReponse"/>
        </div>
        <div v-if="!loading" class="grid grid-cols-4 gap-4 py-2 px-10">
            <div v-for="model in filteredModels" :key='model'                
                class="bg-white hover:no-underline hover:text-black grow flex-col mx-2 p-8 space-y-4 rounded-xl border-2 border-gray-200 transition-shadow duration-500 ease-out cursor-pointer hover:shadow-lg">
                <div class="flex justify-end pb-2">
                    <div v-on:click="onDelete(model.id)">
                        <TrashIcon class="h-5 w-5 hover:text-blue-700"/>
                    </div>
                </div>
                <router-link :to="'models/' + model.id" class="hover:no-underline hover:text-gray-700">
                    <div class="flex items-center space-x-3">
                        <h2 class="relative mx-auto text-blue-900 hover:text-gray-700 text-xl font-semibold">{{ model.name }}</h2>
                    </div>
                    <p class="text-sm leading-relaxed text-gray-500">{{ model.task }} <br/> {{ model.created }} <br/> {{ model.training_status }}</p>
                </router-link>
            </div>
        </div>
    </div>
</template>


<script>
import 'firebase/storage';
import RequestExample from "@/components/cli/RequestExample.vue"
import WarningModal from "@/components/ui/WarningModal.vue"
import { inject } from 'vue'
import { TrashIcon } from '@heroicons/vue/24/outline'
import { Cog8ToothIcon } from '@heroicons/vue/24/outline'
import { ArrowPathIcon } from '@heroicons/vue/24/outline'
import { getUserModels, deleteModel } from "@/meta"

export default {
    components: {
        RequestExample,
        WarningModal,
        TrashIcon,
        Cog8ToothIcon,
        ArrowPathIcon
    },
    props: {
        uuid: String,
    },
    setup() {
        const publicKey = inject("public_key")
        // const models = [{
        //     name: "my_model_1",
        //     date: "10/11/2022",
        //     task: 'Binary Text Classification',
        //     status: "Ready",
        // }]
        return {
            publicKey,
            // models
        }
    },
    mounted() {
        this.updateUserModels()
    },
    computed: {
        filteredModels() {
            return this.models.filter((model) =>
                model.name.toLowerCase().includes(this.searchTerm.toLowerCase())
            );
        },
  },
    data() {
        return {
            models: [],
            loading: false,
            toggleModal: false,
            modalReponse: '',
            selected_model: '',
            searchTerm: ''
        }
    },
    methods: {
        updateUserModels() {
            this.loading = true
            this.models = getUserModels(this.publicKey).then((models) => {
                this.models = models.data
                this.models.forEach((model) => {
                    model.trained = new Date(model.trained);
                });
                const customDateSort = (a, b) => {
                    const dateA = a.created;
                    const dateB = b.created;
                    if (dateA > dateB) {
                        return -1;
                    }
                    if (dateA < dateB) {
                        return 1;
                    }
                    return 0;
                };
                this.models.sort(customDateSort);
                this.models.forEach((model) => {
                    model.created = model.created.split("T")[0];
                });
                this.loading = false;
            })
        },
        onDelete(model_id) {
            this.toggleModal = true
            this.selected_model = model_id
            //deleteModel(this.publicKey, model_id)
            //this.updateUserModels()
        },
        onWarningModalReponse(data) {
            this.modalReponse = data;
            if (this.modalReponse == 'dismiss') {
                this.toggleModal = false
            } 
            if (this.modalReponse == 'delete') {
                deleteModel(this.publicKey, this.selected_model)
                this.loading = true
                this.toggleModal = false
                setTimeout(() => {
                    this.updateUserModels()
                    this.loading = false
                }, 3000);
                
            }
        },
    }
}
</script>

<style scoped>

</style>