<template>
    <div class="grid grid-cols-1 md:grid-cols-4 gap-3 divide-x pt-5 pb-5 px-2">
        <div class="bg-white">
            <div class="flex justify-start md:items-start p-4" v-for="tasks,category in services" :key='category'>
                <div>
                    <h2 class="relative pb-4 text-blue-900 text-left text-base font-bold">{{ category }}</h2>
                    <div class="flex flex-wrap gap-2">
                        <ul class="text-gray-700" v-for='task in tasks' :key='task.name'>
                             <li class='bg-white font-medium text-xs py-1 px-1 border border-gray-100 rounded-md' ><router-link :to='task.router_path'>{{task.displayName}}</router-link></li>
                        </ul>                  
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-white col-span-3">
            <h2 class="text-4xl pt-4 pb-12 text-blue-900 font-bold">{{service.displayName}}</h2>
            <TextInput v-model:text="query_text"/>
            <div v-if="!loading_models && trained_models.length === 0" style="color: darkred;">
                <br/>
                <p>This service requires you to train at least one custom model.</p>
                <p>You can train a custom model <router-link :to="service.router_path + '/training'">here</router-link>.</p>
            </div>
            <div v-else>
                <div v-if="!service.hasDefault || trained_models.length > 1">
                    <DropdownSelect  v-model:selected="selected_model" placeholder="Choose Model" :choices="trained_models"/>
                </div>
            </div>
            <br/>
            <button v-if="!waiting" class="rounded bg-blue-600 font-medium text-white text-sm hover:bg-gray-400 text-gray-800 py-1.5 px-2.5 rounded-l"
                    v-on:click="infer" :disabled="waiting">
                <p>Predict</p>
            </button>
            <button v-if="waiting" disabled type="button" class="py-1.5 px-2 mr-2 text-sm font-medium text-gray-700 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center">
                <svg aria-hidden="true" role="status" class="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
                </svg>
                Processing...
            </button>
            <br/>
            <br/>

            <ClassificationResponse v-if='service.task === "binary"' :response="response"/>
            <MultiClassificationResponse v-if='service.task === "multiclass"' :response="response"/>
            <MultiClassificationResponse v-if='service.task === "multilabel"' :response="response"/>
            <NamedEntityRecognitionResponse v-if='service.name === "named-entity-recognition"' :response="response"/>
            <QuestionAnsweringResponse v-if='service.name.endsWith("qa") || service.name === "question-answering"' :response="response"/>

            <p v-if="response != null" style="white-space: pre-line;">
                <br/>
                End to End time: {{ e2eTime }}ms, Model Prediction Time: {{ response.model_prediction_time }}ms
                <br/>
                <br/>
            </p>

            <div v-if="response != null">
                <Feedback v-if="response != null" :service="service" :uuid="user_id" :payload="feedbackPayload"/>
                <br/>
                <br/>
            </div>
            <div class="relative w-2/4 mx-auto py-4">
                <div class="absolute inset-0 flex items-center">
                    <div class="w-full border-b border-gray-300"></div>
                </div>
                <div class="relative flex justify-center">
                    <span class="bg-white px-4 text-sm text-gray-500">Command Line</span>
                </div>
            </div>
            <RequestExample :url="apiUrl" :payload="payload"/>
        </div>
    </div>
</template>

<script>
import ClassificationResponse from "@/components/inference/ClassificationResponse.vue"
import DropdownSelect from "@/components/ui/DropdownSelect.vue"
import MultiClassificationResponse from "@/components/inference/MultiClassificationResponse.vue"
import NamedEntityRecognitionResponse from "@/components/inference/NamedEntityRecognitionResponse.vue"
import QuestionAnsweringResponse from "@/components/inference/QuestionAnsweringResponse.vue"
import RequestExample from "@/components/cli/RequestExample.vue"
import Feedback from "@/components/inference/InferenceFeedback.vue"
import Spinner from "@/components/ui/Spinner.vue"
import TextInput from "@/components/ui/TextInput.vue"
import axios from "axios";
import { db } from '../main';
import { inject } from 'vue'


export default {
    name: "Inference",
    components: { 
        ClassificationResponse,
        DropdownSelect,
        Feedback,
        MultiClassificationResponse,
        NamedEntityRecognitionResponse,
        NamedEntityRecognitionResponse,
        QuestionAnsweringResponse,
        RequestExample,
        Spinner,
        TextInput,
    },
    created(){
        console.log(this.service)
    },
    setup() {
        const private_key = inject("private_key")
        const public_key = inject("public_key")
        const user_id = inject("firebase_uid")

        return {
            private_key,
            public_key,
            user_id,
        }
    },
    props: {
        services: Object,
        service: Object,
    },
    data() {
        return {
            e2eTime: 0,
            query_text: "",
            response: null,
            selected_model: null,
            trained_models: [],
            loading_models: false,
            waiting: false,
        }
    },
    mounted() {
        // Load user models
        this.loadModels()
    },
    computed: {
        payload: function() {
            const data = {
                public_key: this.public_key,
                text: this.query_text,
                user_id: this.user_id,
            }

            if (!this.service.hasDefault || (this.service.hasDefault && this.selected_model != "default")) {
                if (this.selected_model != null) {
                    data.private_key = this.private_key
                    data.model = this.selected_model
                }
            }
            return data
        },
        feedbackPayload: function() {
            if (this.response === null) {
                return {}
            }

            const data = {
                text: this.query_text,
                user_id: this.user_id,
            }

            if (this.selected_model != null) {
                data.model = this.selected_model
            }

            if (this.service.task === "binary") {
                data.label = this.response.label
                data.score = this.response.score
            } else if (this.service.name === "named-entity-recognition") {
                data.result = this.response.entities[0]
            } else {
                data.result = this.response.results[0]
            }

            return data
        },
        preconditionIssues: function() {
            if (this.query_text.trim().length === 0) return "Enter a non-empty query."
            if (!this.service.hasDefault && this.selected_model === null) return "Select pre-trained model."
            return false
        },
        apiUrl: function() {
            return `https://enterprise.slicex.ai/infer/language/${this.service.name}/`
        }
    },
    methods: {
        loadModels() {
            this.selected_model = null
            this.trained_models = []
            if (this.service.hasDefault) {
                this.trained_models.push("default")
            }
            this.loading_models = true
            db.collection('UserData').doc(this.user_id).collection("Models").where("task_name", "==", this.service.name).get()
                .then(snapshot => {
                    this.loading_models = false
                    snapshot.forEach(model => {
                        this.trained_models.push(model.data().name)
                    })
                    this.trained_models = [...new Set(this.trained_models)]
                })
        },
        infer() {
            if (this.preconditionIssues) {
                alert(this.preconditionIssues)
            } else {
                const start_time = performance.now()
                this.response = null
                this.waiting = true

                axios.post(this.apiUrl, this.payload)
                    .then((response) => {
                        this.e2eTime = parseInt(performance.now() - start_time)
                        this.response = response.data
                        this.waiting = false
                    })
                    .catch((error) => {
                        alert(error.response.data.detail)
                        this.waiting = false
                    })
            }
        }
    },
    watch: {
        "service": {
            handler: function() {
                this.loadModels()
                this.response = null
            },
            deep: true,
            immediate: false,
        },
    },
}
</script>