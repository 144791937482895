<template>
    <div id="alert-additional-content-5" class="p-4 border border-gray-300 rounded-lg bg-gray-50" role="alert">
        <div class="flex items-center  mb-2">
            <svg aria-hidden="true" class="w-5 h-5 mr-2 text-blue-900" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium text-blue-900">Are you sure you want to delete this model?</h3>
        </div>
        <!-- <div class="mt-2 mb-4 text-sm text-gray-700 dark:text-gray-300">
            txt
        </div> -->
        <div class="flex">
            <button v-on:click="updateResponse('delete')" type="button" class="text-white bg-blue-900 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center">
            Delete
            </button>
            <button v-on:click="updateResponse('dismiss')" type="button" class="text-gray-900 bg-transparent border border-gray-700 hover:bg-gray-800 hover:text-blue-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center" data-dismiss-target="#alert-additional-content-5" aria-label="Close">
            Dismiss
            </button>
        </div>
    </div>
</template>

<script>

export default {
  name: "WarningModal",
  props: {
      model_id: ""
  },
  methods: {
        updateResponse(response) {
            this.response = response
            console.log(this.model_id)
            this.emitToParent()
        },
        emitToParent() {
            this.$emit("childToParent", this.response);
        },
  }
};
</script>