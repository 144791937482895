<template>
    <div v-if="response != null">
        <p v-for="entity in response.entities" :key="entity" style="white-space: pre-line;">
            {{ entity.token }}, start_position: {{ entity.start_position }}, label: {{ entity.label }}, score: {{ entity.score.toFixed(2) }}
        </p>
    </div>
</template>

<script>
export default {
    name: "NamedEntityRecognitionResponse",
    props: {
        response: Object,
    },
}
</script>