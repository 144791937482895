<template>
    <div v-if="response != null">
        <p v-for="result in response.results" :key="result" style="white-space: pre-line;">
            label: {{ result[0] }}, score: {{ result[1].toFixed(2) }}
        </p>
    </div>
</template>

<script>
export default {
    name: "MultiClassificationResponse",
    props: {
        response: Object,
    },
}
</script>