export const training_instructions = `
In a ZIP folder, please include train.tsv, dev.tsv, config.json & label_id.json files using the formats below.



1) train.tsv & dev.tsv format: 

For binary & multi class classification, the header (first ) row in each TSV file should be:  “sentence<tab>label”. e.g.,

sentence <tab> label
This is the first test sentence <tab> 0
This is the second test sentence <tab> 3
…

For multilabel classification, the header (first) row in each TSV file should be  “sentence <tab> label_1 <tab> label_2…<tab> label_k”.  For the row entries,  specify 0 or 1 as value for the column corresponding to each class. e.g., multilabel with 3 classes

sentence <tab> label_1 <tab> label_2 <tab> label_3
This is the first test sentence <tab> 0 <tab> 1 <tab> 1
This is the second test sentence <tab> 1 <tab> 1 <tab> 0



2) config.json format with required fields. e.g.,

{ 
    "desc": “dataset-description”, 
    "DATASET_NAME": “my-dataset”, 
    "DATA_DIR": “<path-to-parent-directory-for-data>”, 
    "TRAIN_FILE": "train.tsv",
    “DEV_FILE": "dev.tsv", 
    "field_names": ["sentence", "label"], 
    "field_header": 0, 
    "NUM_CLASS_LABELS": 5
}

NUM_CLASS_LABELS  should be 1 for binary classification or number of classes otherwise.



3) label_id.json format indicating a mapping from label IDs to label names. e.g.,

{
    “0”: “negative”,
    “1”: “positive”
} 

Please contact support@slicex.ai if you have any question.
`