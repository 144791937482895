<template>
    <div class="grid grid-cols-1 md:grid-cols-4 gap-3 divide-x pt-5 pb-5 px-2">
        <div class="bg-white">
            <div class="flex justify-start md:items-start p-4">
                <div>
                    <h2 class="relative pb-4 text-blue-900 text-left text-base font-bold">Language</h2>
                    <div class="flex flex-wrap gap-2" v-for="task in pre_trained_services" :key='task'>
                        <ul class="text-gray-700">
                            <li class='bg-white font-medium text-xs my-0.5 py-1 px-1 border border-gray-100 rounded-md' ><router-link :key="$route.fullPath" :to="'/pre-trained/' + task.urlName">{{task.displayName}} </router-link></li>
                        </ul>                  
                    </div>
                </div>
            </div>
        </div>
        <div v-if="service" class="bg-white col-span-3">
            <h2 class="text-4xl pt-4 pb-0 text-blue-900 font-bold">{{service.name}}</h2>
            <p class="pt-2 pb-12 text-sm text-gray-500"> {{service.description}} </p>
            <TextInput :placeholder="service.example" v-model:text="query_text"/>
            <br/>
            <div v-if="service.urlName == 'email-reply'" class="mx-auto my-4 w-96">
                <p class="block mb-2 text-sm font-medium text-gray-900">Enter a smart-reply to generate the email response:</p>
                <input v-model="smart_reply" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
            </div>
            <div v-if="service.urlName == 'conv-qa'" class="mx-auto my-4 w-96">
                <p class="block mb-2 text-sm font-medium text-gray-900">Enter a question about the context:</p>
                <input v-model="conv_qa_question" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
            </div>
            <button v-if="!waiting" class="rounded bg-blue-600 font-medium text-white text-sm hover:bg-gray-400 text-gray-800 py-1.5 px-2.5 rounded-l"
                    v-on:click="infer" :disabled="waiting">
                <p>Predict</p>
            </button>
            <button v-if="waiting" disabled type="button" class="py-1.5 px-2 mr-2 text-sm font-medium text-gray-700 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center">
                <svg aria-hidden="true" role="status" class="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
                </svg>
                Processing...
            </button>
            <br/>
            <br/>
            <div v-if="response" class="px-5 flex items-center justify-center">
                <InferenceResponse :service_type="service.type" :response="response"/>
            </div>
            <p v-if="response" class="pt-3 pb-8">
                End to End time: <a class="font-medium"> {{ e2eTime }}ms </a>, Model Prediction Time: <a class="font-medium"> {{ inference_time.toFixed(0) }}ms </a>
            </p>
            <div v-if="response">
                <Feedback v-if="response != null" :service="service" :uuid="user_id" :payload="feedbackPayload"/>
                <br/>
                <br/>
            </div>
            <div class="relative w-2/4 mx-auto py-4">
                <div class="absolute inset-0 flex items-center">
                    <div class="w-full border-b border-gray-300"></div>
                </div>
                <div class="relative flex justify-center">
                    <span class="bg-white px-4 text-sm text-gray-500">Command Line</span>
                </div>
            </div>
            <RequestExample :url="apiUrl" :headers="headers" :payload="payload"/>
        </div>
    </div>
</template>

<script>
import InferenceResponse from "@/components/inference/InferenceResponse.vue"
import DropdownSelect from "@/components/ui/DropdownSelect.vue"
import RequestExample from "@/components/cli/RequestExample.vue"
import Feedback from "@/components/inference/InferenceFeedback.vue"
import Spinner from "@/components/ui/Spinner.vue"
import TextInput from "@/components/ui/TextInput.vue"
import axios from "axios";
import { db } from '../main';
import { inject } from 'vue';
import { infer } from '../meta';


export default {
    name: "PreTrainedModel",
    components: { 
        DropdownSelect,
        Feedback,
        InferenceResponse,
        RequestExample,
        Spinner,
        TextInput,
    },
    props: {
    	pre_trained_services: [],
        training_services: [],
    },
    created(){
        const model_id = this.$route.params.model_id
    },
    setup() {
        const public_key = inject("public_key")
        const user_id = inject("firebase_uid")

        return {
            public_key,
            user_id,
        }
    },
    data() {
        return {
            e2eTime: 0,
            inference_time: 0,
            query_text: "",
            smart_reply: "",
            conv_qa_question: "",
            response: null,
            selected_model: null,
            trained_models: [],
            loading_models: false,
            waiting: false,
        }
    },
    mounted() {
        // console.log(service)
        // console.log(service.displayName)
    },
    computed: {
    	service: function() {
    		const model_id = this.$route.params.model_id
    		try {
                for (const service of this.pre_trained_services) {
                    if (service.urlName === model_id) {
                        console.log(service)
                        return service
                    }
                }
                if (this.pre_trained_services.length > 0) {
                    this.$router.replace("/pre-trained/" + this.pre_trained_services[0].urlName)
                }
            } catch (error) {
                //Without this we can't logout from this page
                console.log('')
        }
    	},
        payload: function() {
            if ((this.service.urlName == 'email-reply') && (this.smart_reply != '')) {
                const data = {
                    query: this.query_text,
                    kwargs: {cues: this.smart_reply}
                }
                return data
            } else if ((this.service.urlName == 'conv-qa')) {
                const data = {
                    query: this.conv_qa_question,
                    kwargs: {context: this.query_text}
                }
                return data 
            } else {
                const data = {
                    query: this.query_text
                }
                return data
            }
            
            
        },
        // feedbackPayload: function() {
        //     if (this.response === null) {
        //         return {}
        //     }

        //     const data = {
        //         text: this.query_text,
        //         user_id: this.user_id,
        //     }

        //     if (this.selected_model != null) {
        //         data.model = this.selected_model
        //     }

        //     if (this.service.task === "binary") {
        //         data.label = this.response.label
        //         data.score = this.response.score
        //     } else if (this.service.name === "named-entity-recognition") {
        //         data.result = this.response.entities[0]
        //     } else {
        //         data.result = this.response.results[0]
        //     }

        //     return data
        // },
        preconditionIssues: function() {
            if (this.query_text.trim().length === 0) return "Enter a non-empty query."
            // if (!this.service.hasDefault && this.selected_model === null) return "Select pre-trained model."
            return false
        },
        headers: function() {
            const headers = {
                'Content-Type': 'application/json',
                'x-public-key': this.public_key
            }
            return headers
        },
        apiUrl: function() {
            return this.service.url.single_query_inference
        }
    },
    watch: {
    '$route.fullPath': {
        immediate: true,
        handler () {
        this.resetData()
        }
    }
    },
    methods: {
        resetData () {
            this.response = false
            this.query_text = ""
        },
    	updateService() {
    		console.log("Update Service")
    	},
        infer() {
            if (this.preconditionIssues) {
                alert(this.preconditionIssues)
            } else {
                const start_time = performance.now()
                this.response = null
                this.waiting = true

                infer(this.public_key, this.payload, this.service.url.single_query_inference).then((response) => {
                    this.e2eTime = parseInt(performance.now() - start_time)
                    this.inference_time = response.metadata.model_inference_time_ms
                    this.response = response.data
                    console.log(response)
                    this.waiting = false
                }).catch((error) => {
                        alert(error.response.data.detail)
                        this.waiting = false
                })
            }
        }
    },
}
</script>